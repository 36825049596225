
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ImageDisplayRow extends RcTableViewRow {

  static Id = "ImageDisplayRow";
  
  static Href = "href";
  static Url = "url";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ImageDisplayRow.Id);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  withHrefUrl(href) {
    this.put(ImageDisplayRow.Href, href);
    return this;
  }

  hrefUrl() {
    return this.getString(ImageDisplayRow.Href);
  }

  withImageUrl(url) {
    this.put(ImageDisplayRow.Url, url);
    return this;
  }

  imageUrl() {
    return this.getString(ImageDisplayRow.Url);
  }
}