<template>

    <LoadingRow v-if="ready">
    {{ this.rowData.message() }}
    </LoadingRow>

</template>

<script>

import { mapGetters } from 'vuex'

import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";
import LoadingDisplayRow from "./LoadingDisplayRow.js";

export default {
  name: 'loading-display-cell',
  components: {
    LoadingRow,
  },
  props: {
    align:    { type: String, default: "left" },
    cmpt:     { type: String, default: "title" },
    fontSize: { type: String, default: "medium" },
    subtitle: { type: Boolean, default: false },
    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },
    type:     { type: String, default: "" },
    data:     { type: Object, default: () => {} },
  },
  data() {
    return {
      rowData: new LoadingDisplayRow(),
      ready: false,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
    
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  
  methods: {
    classes: function() {
      var value = "";
      if (this.fontSize) {
        value = "rc-text-" + this.fontSize;
      }

      if (this.subtitle) {
        value = "rc-text-small";
      }
      
      if (this.align === "left") {
        value += " rc-text-left";
      } else if (this.align === "right") {
        value += " rc-text-right";
      } else {
        value += " rc-text-centre";
      }
      return this.background() + " " + value;
    },
    
    background: function() {
      var value = "rc-title-backgroundV2";
      if (this.subtitle) {
        value = "rc-title-background-light";  
      }
      if (this.rowData.savingTitle()) {
        value = "rc-background-good";
        return value;
      }
      if (this.type === "warning") {
        value = " rc-title-warning-left";
      }
      if (this.type === "error") {
        value = " rc-title-error-left";
      }
      return value;
    },
  },
}
</script>