
import RcObject from "@/domain/common/RcObject.js";
// import Schedule from "@/domain/model/schedule/Schedule.js";
import Company from "@/domain/model/company/Company.js";

export default class ScheduleListFilter extends RcObject {
  
  static Fields = {
    CREATED_DATE_RANGE: 'createDateRange',
    PAGINATION: 'pagination',
    COMPANY: Company.MODEL_NAME,
    STATE: "state",
    IDS: "ids",
  }
  
  constructor() {
    super({});
  }
  
  withId(id) {
    return this.withIds([id]);
  }
  
  withIds(ids) {
    this.put(ScheduleListFilter.Fields.IDS, ids);
    return this;
  }
    
  withCompany(companyId) {
    this.put(ScheduleListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withDateRange(range) {
    this.put(ScheduleListFilter.Fields.CREATED_DATE_RANGE, range);
    return this;
  }
  
  withPagination(size, number) {
    this.put(ScheduleListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  done() {
    //
  }
}