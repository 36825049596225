
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class IntegerUpdateRow extends RcTableViewRow {

  static Id = "IntegerUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, IntegerUpdateRow.Id);
  }

}