
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class SignatureDisplayRow extends RcTableViewRow {

  static Id = "SignatureDisplayRow";
  
  static IMAGE = "image";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, SignatureDisplayRow.Id);
  }
  
  withSignature(signature) {
    this.put(SignatureDisplayRow.IMAGE, signature.find().image());
    return this;
  }
  
  image() {
    var data = this.getString(SignatureDisplayRow.IMAGE);
    return data;
  }
}