
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class LoadingDisplayRow extends RcTableViewRow {

  static Id = "LoadingDisplayRow";
  
  static Fields = {
      Message: "message",
  }
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, LoadingDisplayRow.Id);
  }

  withMessage(message) {
    this.put(LoadingDisplayRow.Fields.Message, message);
    return this;
  }
  
  message() {
    return this.getString(LoadingDisplayRow.Fields.Message);
  }
}