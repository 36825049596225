
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class VehicleUnitUpdateRow extends RcTableViewRow {

  static Id = "VehicleUnitUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, VehicleUnitUpdateRow.Id);
  }

  defaultValue() {
    var value = super.defaultValue();
    if (value["@rid"]) {
      value = value["@rid"];
    }
    return value;
  }
}