<template>

  <Row v-if="ready">
    <a :href="rowData.hrefUrl()">
      <img width="25%" src="@/assets/ReadyChekIcon.png" />
    </a>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import MC from "@/domain/session/MC.js";
import ImageDisplayRow from "./ImageDisplayRow.js";
import Row from '@/portals/shared/library/box/Row.vue';
export default {
  name: 'image-display-cell',
  components: {
    Row,
  },
  props: {
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ImageDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>