
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class StringListUpdateRow extends RcTableViewRow {

  static Id = "StringListUpdateRow";
  static Options = "options";
  static ShowClear = "showClear";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, StringListUpdateRow.Id);
    this.withOptions([]);
    this.withShowClear(false); 
  }

  withOptions(options) {
    this.put(StringListUpdateRow.Options, options);
    return this;
  }

  options() {
    return this.get(StringListUpdateRow.Options);
  }
  
  withShowClear(showClear) {
    this.put(StringListUpdateRow.ShowClear, showClear);
    return this;
  }

  showClear() {
    return this.getBoolean(StringListUpdateRow.ShowClear);
  }
}