
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class SpacerDisplayRow extends RcTableViewRow {

  static Id = "SpacerDisplayRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, SpacerDisplayRow.Id);
  }

}