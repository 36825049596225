
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class JurisdictionUpdateRow extends RcTableViewRow {

  static Id = "JurisdictionUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, JurisdictionUpdateRow.Id);
  }

}