
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ValueDisplayRow extends RcTableViewRow {

  static Id = "ValueDisplayRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ValueDisplayRow.Id);
  }
  
  
}