<template>

    <Row v-if="ready" :key="redraw">
     <Column :width="15" class="w3-center">
       <img v-if="showPlaceholder && placeholderKey === 'truck'" src="@/assets/2019/pickup_front.png" style="width: 216px; height:194px" />
       <img v-else-if="showPlaceholder && placeholderKey === 'bus'" src="@/assets/2019/bus.png" style="width: 216px; height:194px" />
       <img v-else-if="showPlaceholder && placeholderKey === 'trailer'" src="@/assets/2019/trailer.png" style="width: 216px; height:194px" />
       <VehicleImage v-else :defaultValue="defaultValue" :imageData="imageData" size="width:250px"/>
     </Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import VehicleImage from '@/views/portal/vehicles/VehicleImage.vue';
//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import PictureDisplayRow from "./PictureDisplayRow.js";

export default {
  name: 'picture-display-cell',
  components: {
    VehicleImage,
    //Box, 
    Row, Column,
  },
  props: {
    align:    { type: String, default: "left" },
    cmpt:     { type: String, default: "title" },
    fontSize: { type: String, default: "medium" },
    subtitle: { type: Boolean, default: false },
    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },
    type:     { type: String, default: "" },
    data:     { type: Object, default: () => {} },
    placeholderKey: { type: String, default: "truck" },
    imageData: { type: String, default: null },
  },
  data() {
    return {
      defaultValue: {},
      rowData: new PictureDisplayRow(),
      ready: false,
      showPlaceholder: true,
      redraw: 0,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
  },
  watch: {
    data() {
      this.rowData.data = this.data;
      this.defaultValue = this.rowData.defaultValue();
      if (this.defaultValue) {
        this.showPlaceholder = false;
      }
      this.redraw = this.redraw + 1;
    }
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.defaultValue = this.rowData.defaultValue();
    if (this.defaultValue) {
      this.showPlaceholder = false;
    }
    this.ready = true;
  },
  
  methods: {
    placeholder: function() {
      if (this.placeholderKey === "bus") {
        return "@/assets/2019/bus.png";
      }
      if (this.placeholderKey === "trailer") {
        return "@/assets/2019/trailer.png";
      }
      return "@/assets/2019/pickup_front.png";
    },
    
    classes: function() {
      var value = "";
      if (this.fontSize) {
        value = "rc-text-" + this.fontSize;
      }

      if (this.subtitle) {
        value = "rc-text-small";
      }
      
      if (this.align === "left") {
        value += " rc-text-left";
      } else if (this.align === "right") {
        value += " rc-text-right";
      } else {
        value += " rc-text-centre";
      }
      return this.background() + " " + value;
    },
    
    background: function() {
      var value = "rc-title-backgroundV2";
      if (this.subtitle) {
        value = "rc-title-background-light";  
      }
      if (this.rowData.savingTitle()) {
        value = "rc-background-good";
        return value;
      }
      if (this.type === "warning") {
        value = " rc-title-warning-left";
      }
      if (this.type === "error") {
        value = " rc-title-error-left";
      }
      return value;
    },
  },
}
</script>