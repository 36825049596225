<template>

  <Row v-if="ready" :class="rowData.even() ? 'rowEven' : 'rowOdd'">
    <Column :width="3" class="rc-text-label">
      <Box>
        <Row>
          <Column :width="4" class="rc-text-centre"></Column>
          <Column :width="4" class="rc-text-centre"></Column>
          <Column :width="4" class="rc-text-centre"></Column>
          <Column :width="3" class="rc-text-centre">{{ rowData.index() + 1 }}</Column>
        </Row>
      </Box>
    </Column>
    <Column :width="12" class="rc-text-label">
      <Box>
        <Row>
          <Column :width="15">{{ rowData.label() }} </Column>
        </Row>
      </Box>
    </Column>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import ChecklistItemDisplayRow from "./ChecklistItemDisplayRow.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'checklist-item-display-cell',
  components: {
    Box, Row, Column,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ChecklistItemDisplayRow(),
      Contents: ContentUtils,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>