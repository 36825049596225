
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class StringDisplayRow extends RcTableViewRow {

  static Id = "StringDisplayRow";
  static FontSize = "fontSize";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, StringDisplayRow.Id);
    this.withAlign("centre");
    this.withFontSize("mediumn");
  }
  
  withAlignLeft() {
    this.withAlign("left");
    return this;
  }
  
  withAlign(align) {
    this.put("align", align);
    return this;
  }
  
  align() {
    return this.getString("align");
  }

  withStyleEven() {
    this.withStyle("rowEven");
    return this;
  }
  
  withStyle(style) {
    this.put("style", style);
    return this;
  }
  
  style() {
    return this.getString("style");
  }

  fontSize() {
    return this.getString(StringDisplayRow.FontSize);
  }

  withFontSize(fontSize) {
    this.put(StringDisplayRow.FontSize, fontSize);
    return this;
  }
}