<template>

  <Row :tag="row.key()">
    <Column :width="13" />
    <Column :width="2">
      {{ ContentUtils.decode(row.name()) }}
    </Column>
  </Row>
  
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import ContentUtils from '@/utils/ContentUtils.js';

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import BoolDisplayRow from "./BoolDisplayRow.js";

export default {
  name: 'button-display-cell',
  components: {
    Row, Column
  },
  props: {
    css:    { type: String, default: "" },
    data: null,
  },
  data() {
    return {
      row : new BoolDisplayRow(),
      MC : new MC(),
      ready: false,
      ContentUtils: ContentUtils,
      BoolDisplayRow: BoolDisplayRow,
    };
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    backgroundType: function() {
      return this.row.isBackgroundPrimaryAction();
    },
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  methods: {
    pressed: function() {
      var data = {};
      data['id'] = this.row.id();
      data['object'] = this.row.object();
      data['field'] = this.row.field();
      this.$emit('click', data);
    },
  },
}
</script>