<template>

  <LabelValueRow>
    <template #label>Status</template>
    <template #value>
      <Box>
        <Row>
          <div class="rc-box-col-10 rc-input-good rc-text-centre" 
            v-if="isDefectTypeGood()">
            {{ defectTypeDisplay() }}
          </div>
          <div class="rc-box-col-10 rc-input-minor rc-text-centre" 
            v-else-if="isDefectTypeMinor()">
            {{ defectTypeDisplay() }}
          </div>
          <div class="rc-box-col-10 rc-input-major rc-text-centre" 
            v-else-if="isDefectTypeMajor()">
            {{ defectTypeDisplay() }}
          </div>
          <div class="rc-box-col-5" />
        </Row>
      </Box>
    </template>
  </LabelValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';

import DefectTypeDisplayRow from "./DefectTypeDisplayRow.js";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

import MC from "@/domain/session/MC.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
//import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'defecttype-display-cell',
  components: {
    LabelValueRow,
    Box,Row, 
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: {},
  },
  data() {
    return {
      ready: false,
      rowData: new DefectTypeDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    defectType() {
      return this.rowData.value();
    },
    isDefectTypeGood() {
      var defectType = this.defectType();
      if (defectType === "Major") {
        return false;
      }
      if (defectType === "Minor") {
        return false;
      }
      return true;
    },

    isDefectTypeMajor() {
      var defectType = this.defectType();
      if (defectType === "Major") {
        return true;
      }
      return false;
    },

    isDefectTypeMinor() {
      var defectType = this.defectType();
      if (defectType === "Minor") {
        return true;
      }
      return false;
    },

    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    },

    defectTypeDisplay() {
      var defectType = this.defectType();
      var display = "Good - No Defects";

      if (defectType === "Major") {
        display = "Major Defect - Out of Service";
      }
      if (defectType === "Minor") {
        display = "Minor";
      }
      return display;
    }
  },
}
</script>