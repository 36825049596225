
import RcObject from "@/domain/common/RcObject.js";
import Company from "@/domain/model/company/Company.js";
import Employee from "@/domain/model/employee/Employee.js";

export default class ChecklistListFilter extends RcObject {
  
  static Fields = {
      PAGINATION: 'pagination',
      EMPLOYEE: Employee.MODEL_NAME,
      COMPANY: Company.MODEL_NAME,
      IDS: 'ids',
  }
  
  constructor() {
    super({});
  }
  
  withCompany(companyId) {
    this.put(ChecklistListFilter.Fields.COMPANY, companyId);
    return this;
  }
  
  withPagination(size, number) {
    this.put(ChecklistListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withEmployee(employee) {
    if (!employee) {
      return this;
    }
    this.put(ChecklistListFilter.Fields.EMPLOYEE, employee);
    return this;
  }
  
  with(map) {
    this.put(map.modelName(), map.data);
    return this;
  }
  
  withIds(ids) {
    this.put(ChecklistListFilter.Fields.IDS, ids);
    return this;
  }
  
  done() {
    //
  }
}