<template>

    <Row v-if="ready">
      <Column :width="3" />
      <Column v-if="imageOne" :width="1" class="w3-center" :showBorder="false">
       <a :href="imageOne" target=”_blank”><img :src="imageOne" style="width: 75px; height:100px" /></a>
      </Column>
      <Column v-else :width="1" />
      
      <Column v-if="imageTwo" :width="1" class="w3-center" :showBorder="false">
       <a :href="imageTwo" target=”_blank”><img :src="imageTwo" style="width: 75px; height:100px" /></a>
      </Column>
      <Column v-else :width="1" />
      
      <Column v-if="imageThree" :width="1" class="w3-center" :showBorder="false">
       <a :href="imageThree" target=”_blank”><img :src="imageThree" style="width: 75px; height:100px" /></a>
      </Column>
      <Column v-else :width="1" />
      
      <Column v-if="imageFour" :width="1" class="w3-center" :showBorder="false">
        <a :href="imageFour" target=”_blank”><img :src="imageFour" style="width: 75px; height:100px" /></a>
      </Column>
      <Column v-else :width="1" />
      
      <Column :width="8" />
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

//import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Comment from "@/domain/model/comment/Comment.js";

import CommentImageDisplayRow from "./CommentImageDisplayRow.js";

export default {
  name: 'comment-image-display-cell',
  components: {
    //Box, 
    Row, Column,
  },
  props: {
    data: { type: Object, default: () => {} },
  },
  data() {
    return {
      row: new CommentImageDisplayRow(),
      ready: false,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    imageOne: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 0) {
        var image = images[0];
        return image.imageDisplay();
      }
      return null;
    },
    imageTwo: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 1) {
        var image = images[1];
        return image.imageDisplay();
      }
      return null;
    },
    imageThree: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 2) {
        var image = images[2];
        return image.imageDisplay();
      }
      return null;
    },
    imageFour: function() {
      var comment = new Comment(this.domain, this.row.object());
      var imageMap = comment.images().find();
      if (imageMap.isEmpty()) {
        return null;
      }
      var images = imageMap.sortByIndex();
      if (images.length > 3) {
        var image = images[3];
        return image.imageDisplay();
      }
      return null;
    },
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  
  methods: {
  },
}
</script>