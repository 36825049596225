<template>

  <Box>
    <SessionTimeoutDialog v-if="showSessionTimeoutDialog" />
    <Row>
      <Column :width="15">
        <Box :key="redraw">
          <Row v-for="(row, rowIndex) in tableData" :key="rowIndex">

            <CellFactory :rowData="row.data" :showMissingRequirements="showMissingRequirements"
              v-on:withValue="withValue" v-on:withButtonPressed="withButtonPressed" />

          </Row>
        </Box>
      </Column>
    </Row>
  </Box>

</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

//import ErrorRow from '@/components/row/ErrorRow.vue';
//import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
//import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import VehicleAddModel from "../add/VehicleAddModel.js";
import VehicleAddUtils from "../add/VehicleAddUtils.js";
import Vehicle from "@/domain/model/vehicle/Vehicle.js";

import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';
import CellFactory from "@/portals/shared/cell/CellFactory.vue";

//import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";
import SessionTimeoutDialog from "@/portals/shared/views/session/SessionTimeoutDialog.vue";

export default {
  name: "vehicle-edit-view",
  components: {
    Column, Box, Row,
    CellFactory,
    SessionTimeoutDialog
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      redraw: 1,

      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,

      CellFactory: CellFactory,

      tableData: [],
      addModel: null,
      timeout: null,
      ready: false,

      showSavingDialog: false,
      showSessionTimeoutDialog: false,

      VehicleAddUtils: VehicleAddUtils,
      TitleDisplayRow: TitleDisplayRow,
      Vehicle: Vehicle,
    };
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'auth_client',
      'domain',
    ]),
    itemId: function () {
      return this.$route.params['id'];
    },
    isSaving: function () {
      return this.addModel.isSaving();
    },
    showMissingRequirements: function () {
      return this.addModel.showMissingRequirements();
    }
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
      'AuthStorage_updateCompany',
      'addListenerAction',
      'removeListenerAction',
      'updateDomain',
    ]),

    start: function () {
      var vehicle = this.domain.vehicles().findById(this.itemId);
      this.addModel = new VehicleAddModel(this, vehicle);
      this.model().start();
      this.tableData = this.model().tableData();
      this.ready = true;
    },

    callback: function (msg) {
      this.loadingMessage = msg;
    },

    model: function () {
      return this.addModel;
    },

    withValue: function (kvp) {
      this.model().withValue(kvp);
    },

    withButtonPressed: function (buttonData) {
      this.model().withButtonPressed(buttonData);
    },

    populateData: function () {
      this.tableData = [];
      this.reloadData();
    },

    reloadData: function () {
      this.model().populateData();
      this.tableData = this.model().tableData();
      this.redraw += 1;
    },

  },
}
</script>