<template>

  <Row>
    <Column :width="3" class="rc-font-bold rc-text-label">
      <Box>
        <Row>
          <Column :width="4" class="rc-text-centre">In</Column>
          <Column :width="4" class="rc-text-centre">En</Column>
          <Column :width="4" class="rc-text-centre">Re</Column>
          <Column :width="3" class="rc-text-centre">#</Column>
        </Row>
      </Box>
    </Column>
    <Column :width="12" class="rc-font-bold rc-text-label">
      <Box>
        <Row>
          <Column :width="15">{{ MC.Inspection.Details.ScheduleTitle.value() }} </Column>
        </Row>
      </Box>
    </Column>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import ScheduleHeaderDisplayRow from "./ScheduleHeaderDisplayRow.js";
import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'schedule-header-display-cell',
  components: {
    Box, Row, Column,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new ScheduleHeaderDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>