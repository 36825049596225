
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ChecklistItemUpdateRow extends RcTableViewRow {

  static Id = "ChecklistItemUpdateRow";
  static Item = "item";
  static Index = "index";
  static MaxIndex = "maxIndex";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistItemUpdateRow.Id);
  }

  withItem(item) {
    this.put(ChecklistItemUpdateRow.Item, item);
    return this;
  }
  
  item() {
    return this.get(ChecklistItemUpdateRow.Item);
  }

  index() {
    return this.getInt(ChecklistItemUpdateRow.Index);
  }
  
  withIndex(index) {
    this.put(ChecklistItemUpdateRow.Index, index);
    return this;
  }
  
  withMaxIndex(max) {
    this.put(ChecklistItemUpdateRow.MaxIndex, max);
    return this;
  }
  
  maxIndex() {
    return this.getInt(ChecklistItemUpdateRow.MaxIndex);
  }
}