<template>

  <SpacerRow v-if="ready" />
  
</template>

<script>

import { mapGetters } from 'vuex'

import SpacerDisplayRow from "./SpacerDisplayRow.js";

import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";

export default {
  name: 'spacer-display-cell',
  components: {
    SpacerRow,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new SpacerDisplayRow(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
  },
}
</script>