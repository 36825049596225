
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ChecklistItemRemarksDisplayRow extends RcTableViewRow {

  static Id = "ChecklistItemRemarksDisplayRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistItemRemarksDisplayRow.Id);
  }
}