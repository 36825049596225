
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class PictureDisplayRow extends RcTableViewRow {

  static Id = "PictureDisplayRow";
  
  static Fields = {
      Image: "image",
  }
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, PictureDisplayRow.Id);
  }

  withImage(image) {
    this.put(PictureDisplayRow.Fields.Image, image);
    return this;
  }
  
  image() {
    return this.getString(PictureDisplayRow.Fields.Image);
  }
  
  defaultValue() {
    return this.data;
  }
}
