
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ChecklistItemOptionDisplayRow extends RcTableViewRow {

  static Id = "ChecklistItemOptionDisplayRow";
  
  static INDEX = "index";
  static INITIAL = "initial";
  static CATEGORY = "category";
  static REPAIRED = "repaired";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistItemOptionDisplayRow.Id);
    this.withInitial(true);
    this.withRepaired(false);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  
  index() {
    return this.getInt(ChecklistItemOptionDisplayRow.INDEX);
  }
  
  withIndex(index) {
    this.put(ChecklistItemOptionDisplayRow.INDEX, index);
    return this;
  }
  
  
  withInitial(initial) {
    this.put(ChecklistItemOptionDisplayRow.INITIAL, initial);
    return this;
  }
  
  isInitial() {
    return this.getBoolean(ChecklistItemOptionDisplayRow.INITIAL);
  }
  
  withCategory(category) {
    this.put(ChecklistItemOptionDisplayRow.CATEGORY, category);
    return this;
  }
  
  category() {
    return this.getString(ChecklistItemOptionDisplayRow.CATEGORY);
  }
  
  hasInitialGood() {
    var value = false;
    if (this.isInitial()) {
      if (this.category() === "none") {
        value = true;
      }
    }
    return value;
  }
  
  hasInitialMinor() {
    var value = false;
    if (this.isInitial()) {
      if (this.category() === "minor") {
        value = true;
      }
    }
    return value;
  }
  
  hasInitialMajor() {
    var value = false;
    if (this.isInitial()) {
      if (this.category() === "major") {
        value = true;
      }
    }
    return value;
  }

  hasEnrouteGood() {
    var value = false;
    if (!this.isInitial()) {
      if (this.category() === "none") {
        value = true;
      }
    }

    return value;
  }
  
  hasEnrouteMinor() {
    var value = false;
    if (!this.isInitial()) {
      if (this.category() === "minor") {
        value = true;
      }
    }

    return value;
  }
  
  hasEnrouteMajor() {
    var value = false;
    if (!this.isInitial()) {
      if (this.category() === "major") {
        value = true;
      }
    }
    return value;
  }
  
  isRepaired() {
    return this.getBoolean(ChecklistItemOptionDisplayRow.REPAIRED);
  }
  
  withRepaired(repaired) {
    this.put(ChecklistItemOptionDisplayRow.REPAIRED, repaired);
    return this;
  }
}