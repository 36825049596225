
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class PictureUpdateRow extends RcTableViewRow {

  static Id = "PictureUpdateRow";
  
  static Fields = {
      Image: "image",
  }
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, PictureUpdateRow.Id);
  }

  withImage(image) {
    this.put(PictureUpdateRow.Fields.Image, image);
    return this;
  }
  
  image() {
    return this.getString(PictureUpdateRow.Fields.Image);
  }
}