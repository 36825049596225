<template>

  <Row :showBorder="showBorder" v-if="ready" :borderStyle="row.borderStyle()" :class="this.style">

    <Column v-for="(child, childIndex) in children" 
            :key="childIndex" :width="child.width()">
      <Box>
        <Row>
          <ChildCellFactory 
            :rowData="child.data" 
            :showMissingRequirements="showMissingRequirements"
            v-on:withValue="withValue"
            v-on:withButtonPressed="withButtonPressed" />
        </Row>
      </Box>
    </Column>
  </Row>
  
</template>

<script>

import { mapGetters } from 'vuex'

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import RowDisplayRow from "./RowDisplayRow.js";

export default {
  name: 'row-display-cell',
  components: {
    Box, Row, Column,
    ChildCellFactory: () => import("@/portals/shared/cell/CellFactory.vue")
  },
  props: {
    css:    { type: String, default: "" },
    showBorder: { type: Boolean, default: false },
    id:  { type: String, default: "id" },
    children: {type: Array, default: () => []},
    showMissingRequirements: { type: Boolean, default: false },
    data: { type: Object, default: () => { } },
  },
  data() {
    return {
      MC : new MC(),
      ready: false,
      row: new RowDisplayRow(),
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    style: function() {
      if (this.row.isBackgroundTitle()) {
        return "rc-title-backgroundV2";
      }
      if (this.row.isBackgroundSubtitle()) {
        return "rc-title-background-light";
      }
      return "";
    }
  },
  mounted: function () {
    this.row.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      this.$emit('withValue', kvp);
    },
    withButtonPressed: function(buttonData) {
      this.$emit('withButtonPressed', buttonData );
    }
  },
}
</script>