
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class VehicleBrakeUpdateRow extends RcTableViewRow {

  static Id = "VehicleBrakeUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, VehicleBrakeUpdateRow.Id);
  }

  defaultValue() {
    var value = super.defaultValue();
    if (value["@rid"]) {
      value = value["@rid"];
    }
    return value;
  }
}