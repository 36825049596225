<template>

  <LabelValueRow>
    <template #label>State</template>
    <template #value>
      <Box>
        <Row>
          <div class="rc-box-col-10 rc-text-left" 
            v-if="isActive()">
            Active
          </div>
          <div class="rc-box-col-10 rc-text-left" 
            v-else-if="isInactive()">
            Inactive
          </div>
          <div class="rc-box-col-10 rc-text-left" 
            v-else-if="isInvited()">
            Invited
          </div>
          <div class="rc-box-col-10 rc-text-left" 
            v-else-if="isNotInvited()">
            Not Invited
          </div>
          <div class="rc-box-col-10 rc-text-left" 
            v-else-if="isRemoved()">
            Removed
          </div>
          <div class="rc-box-col-10 rc-text-left" 
            v-else-if="isDeleted()">
            Deleted
          </div>
        </Row>
      </Box>
    </template>
  </LabelValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';

import StateDisplayRow from "./StateDisplayRow.js";
import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

import MC from "@/domain/session/MC.js";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
//import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'state-display-cell',
  components: {
    LabelValueRow,
    Box,Row, 
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: {},
  },
  data() {
    return {
      ready: false,
      rowData: new StateDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    isActive() {
      return this.rowData.value() === "active";
    },
    isInactive() {
      return this.rowData.value() === "inactive";
    },
    isInvited() {
      return this.rowData.value() === "invited";
    },
    isRemoved() {
      return this.rowData.value() === "removed";
    },
    isNotInvited() {
      return this.rowData.value() === "notInvited";
    },
    isDeleted() {
      return this.rowData.value() === "deleted";
    },
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>