<template>
  <Row v-if="ready">
    <div v-if="left" :class="leftPadding"> </div>
    <div :class="middle" ref="integer">
      <Box>
        <Row v-if="showLabel">
          <Column :width="15">
            <div v-if="isTitleShowing" class="rc-font-small rc-text-label">
              {{ placeholderText() }}
            </div>
            <div v-else class="rc-font-small">
              <div v-if="placeholder != null" > 
                <div class="rc-font-small rc-text-label"> <br> </div>
              </div>
            </div>
          </Column>
        </Row>
        <Row>
          <Column :width="15">
            <Box>
              <Row>
                <Column :width="15">
                  <input class="rc-input-field-v2 rc-input-width rc-text-value"
                    :placeholder="placeholderText()" 
                    :disable="disabled"
                    type="number" 
                    v-model="value"
                    @input="(e) => value = e.target.value"
                    v-on:keydown.enter="enter"
                    v-on:input="withValue"
                    style="height: 30px; padding-left: 20px; padding-right: 20px;"
                    />
                </Column>
              </Row>
            </Box>
            <Box>
              <Row v-if="!shouldShowRequired && !Strings.isEmpty(hint)">
                <Column :width="15">
                  <div class="rc-font-small rc-text-value">{{ hint }} <br /></div>
                </Column>
              </Row>
              <Row v-if="shouldShowRequired">
                <Column :width="15">
                  <div v-if="shouldShowRequired" class="rc-font-small rc-background-required">A value for {{ C.get(placeholder) }} is required</div>
                </Column>
              </Row>
            </Box>
          </Column>
        </Row>
      </Box>
    </div>
    <div v-if="right" :class="rightPadding"> </div>
  </Row>
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils from '@/utils/StringUtils.js';
import Catalog from '@/domain/session/Catalog.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'key-integer',
  components: {
    Box, Row, Column,
  },
  props: {
    title:  String,
    defaultValue: { type: String, default: null },
    placeholder:  String,
    showLabel: {type: Boolean, default: true},
    hint: { type: String, default: ""},
    hintUrl: { type: String, default: ""},
    disabled: {type: Boolean, default: false},
    left:   {type: Number, default: 0},
    right:  {type: Number, default: 0},
    mandatory: {type: Boolean, default: false},
    required:  {type: Boolean, default: false},
    clearValue: {type: Boolean, default: false},
  },
  data() {
    return {
      value: null,
      isTitleShowing: false,
      C: new Catalog(),
      timer: null,
      Strings: StringUtils,
      ready: false,
    };
  },
  computed: {
    ...mapGetters([
      'domain',
    ]),
    shouldShowRequired: function() {
      if (this.required) {
        if (this.mandatory) {
          if (StringUtils.isEmpty(this.value)) {
            return true;
          }
        }
      }
      return false;
    },
    leftPadding: function() {
      if (this.left > 0) {
        return "rc-box-col-" + this.left;
      }
      return "";
    },
    rightPadding: function() {
      if (this.right > 0) {
        return "rc-box-col-" + this.right;
      }
      return "";
    },
    middle: function() {
      var value = (15 - this.left - this.right);
      return "rc-box-col-" + value;
    },
  },
  watch: {
    value() {
      this.validate();
    },
    required() {
      if (this.required) {
        var element = this.$refs["integer"];
        if (element.scrollIntoView) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    clearValue() {
      if (this.clearValue) {
        this.value = null;
      }
    },
  },
  mounted: function() {
    this.value = StringUtils.isEmpty(this.defaultValue) ? null : parseInt(this.defaultValue);
    this.validate();
    this.ready = true;
  },
  methods: {
    placeholderText: function() {
      if (this.mandatory) {
        return this.C.get(this.placeholder) + "*";
      }
      return this.C.get(this.placeholder);
    },
    validate: function() {
      if (this.value != null) {
        this.isTitleShowing = true;
      } else {
        this.isTitleShowing = false;
      }
      if (this.mandatory) {
        this.$emit('requirementsMet', false);
      }
    },
    withValue: function() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.emit();
    },
    emit: function() {
      clearInterval(this.timer)
      this.$emit('value', {'key': this.title, 'value': this.value})
    },
    enter: function() {
      this.emit();
      this.$emit('enter', this.title);
    },
  },

}
</script>
