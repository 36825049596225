<template>
    <Column :width="width" :showBorder="showBorder" v-if="ready">
    
      <Box v-if="typeId === TitleDisplayRow.Id">
        <TitleDisplayCell
          :showMissingRequirements="showMissingRequirements"
          :subtitle="data['subtitle']"
          :align="data.align"
          :data="data" > 
          {{ data[TitleDisplayRow.Title] }} 
        </TitleDisplayCell>
      </Box>

      <Box v-if="typeId === StringUpdateRow.Id">
        <StringUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[StringUpdateRow.Value] }}
          
        </StringUpdateCell>
      </Box>

      <Box v-if="typeId === StringListUpdateRow.Id">
        <StringListUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[StringListUpdateRow.Value] }}
          
        </StringListUpdateCell>
      </Box>

      <Box v-if="typeId === CheckboxUpdateRow.Id">
        <CheckboxUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[CheckboxUpdateRow.Value] }}
          
        </CheckboxUpdateCell>
      </Box>

      <Box v-if="typeId === PasswordUpdateRow.Id">
        <PasswordUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[StringUpdateRow.Value] }}
          
        </PasswordUpdateCell>
      </Box>

      <Box v-if="typeId === BoolUpdateRow.Id">
        <BoolUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[BoolUpdateRow.Value] }}
          
        </BoolUpdateCell>
      </Box>
        
      <Box v-if="typeId === TextAreaUpdateRow.Id">
        <TextAreaUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[TextAreaUpdateRow.Title] }}
          
        </TextAreaUpdateCell>
      </Box>

      <Box v-if="typeId === EmailUpdateRow.Id">
        <EmailUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[EmailUpdateRow.Title] }}
          
        </EmailUpdateCell>
      </Box>

      <Box v-if="typeId === PhoneNumberUpdateRow.Id">
        <PhoneNumberUpdateCell
            :showMissingRequirements="showMissingRequirements"
            :data="data"
            v-on:withValue="withValue" > 
        
          {{ data[PhoneNumberUpdateRow.Title] }}
          
        </PhoneNumberUpdateCell>
      </Box>

      <Box v-if="typeId === ButtonDisplayRow.Id">
        <ButtonDisplayCell
            :data="data"
            v-on:click="withButtonPressed"
            v-on:withValue="withValue" > 
        </ButtonDisplayCell>
      </Box>
      
      <Box v-if="typeId === BoxDisplayRow.Id">
        <BoxDisplayCell 
          :showMissingRequirements="showMissingRequirements"
          :showBorder="data[BoxDisplayRow.Border]"
          :children="data[BoxDisplayRow.Kids]"
          :data="data"
          v-on:withButtonPressed="withButtonPressed"
          v-on:withValue="withValue">
        </BoxDisplayCell>
      </Box>
 
      <Box v-if="typeId === RowDisplayRow.Id">
        <RowDisplayCell
          :showMissingRequirements="showMissingRequirements"
          :showBorder="data[BoxDisplayRow.Border]"
          :children="data[BoxDisplayRow.Kids]"
          :data="data"
          v-on:withButtonPressed="withButtonPressed"
          v-on:withValue="withValue">
        </RowDisplayCell>
      </Box>
      
      <Box v-if="typeId === ChecklistItemUpdateRow.Id">
        <ChecklistItemUpdateCell 
          :data="data"
          :showMissingRequirements="showMissingRequirements"
          v-on:withButtonPressed="withButtonPressed"
          v-on:withValue="withValue">
        </ChecklistItemUpdateCell>
      </Box>
           
       <Box v-if="typeId === ChecklistItemOptionUpdateRow.Id">
        <ChecklistItemOptionUpdateCell 
          :data="data"
          :showMissingRequirements="showMissingRequirements"
          :showRemove="showRemove"
          v-on:withButtonPressed="withButtonPressed"
          v-on:withValue="withValue">
        </ChecklistItemOptionUpdateCell>
      </Box>

      <Box v-if="typeId === CancellingDisplayRow.Id">
        <CancellingDisplayCell
         v-on:click="withButtonPressed" 
         v-on:withValue="withValue"
         :data="data" />
      </Box>
      
      <Box v-if="typeId === SavingDisplayRow.Id">
        <SavingDisplayCell
         v-on:click="withButtonPressed"
         v-on:withValue="withValue"  
         :data="data" />
      </Box>

      <Box v-if="typeId === LoadingDisplayRow.Id">
        <LoadingDisplayCell
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>
      
      <Box v-if="typeId === PictureDisplayRow.Id">
        <PictureDisplayCell
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>
      
      <Box v-if="typeId === PictureUpdateRow.Id">
        <PictureUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>
      
      <Box v-if="typeId === VehicleTypeUpdateRow.Id">
        <VehicleTypeUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>

      <Box v-if="typeId === VehicleBrakeUpdateRow.Id">
        <VehicleBrakeUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>
      
      <Box v-if="typeId === VehicleScheduleUpdateRow.Id">
        <VehicleScheduleUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>
      
      <Box v-if="typeId === VehicleUnitUpdateRow.Id">
        <VehicleUnitUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>

      <Box v-if="typeId === JurisdictionUpdateRow.Id">
        <JurisdictionUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>

      <Box v-if="typeId === DateUpdateRow.Id">
        <DateUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>

      <Box v-if="typeId === IntegerUpdateRow.Id">
        <IntegerUpdateCell
         :showMissingRequirements="showMissingRequirements"
         v-on:click="withButtonPressed"
         v-on:withValue="withValue" 
         :data="data" />
      </Box>

      <Box v-if="typeId === ChecklistUpdateRow.Id">
        <ChecklistUpdateCell 
          :showMissingRequirements="showMissingRequirements"
          v-on:click="withButtonPressed"
          v-on:withValue="withValue" 
          :data="data" />
      </Box>

      <Box v-if="typeId === StringDisplayRow.Id">
        <StringDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === CommentImageDisplayRow.Id">
        <CommentImageDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === StatusDisplayRow.Id">
        <StatusDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ValueDisplayRow.Id">
        <ValueDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === LabelValueDisplayRow.Id">
        <LabelValueDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === LabelDisplayRow.Id">
        <LabelDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === VehicleImageDisplayRow.Id">
        <VehicleImageDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === SignatureDisplayRow.Id">
        <SignatureDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === ScheduleHeaderDisplayRow.Id">
        <ScheduleHeaderDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ScheduleQuestionDisplayRow.Id">
        <ScheduleQuestionDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ScheduleQuestionOptionDisplayRow.Id">
        <ScheduleQuestionOptionDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ScheduleQuestionRemarksDisplayRow.Id">
        <ScheduleQuestionRemarksDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ChecklistHeaderDisplayRow.Id">
        <ChecklistHeaderDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ChecklistItemDisplayRow.Id">
        <ChecklistItemDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === ChecklistItemOptionDisplayRow.Id">
        <ChecklistItemOptionDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === ImageDisplayRow.Id">
        <ImageDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === EmailImageDisplayRow.Id">
        <EmailImageDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === RemarksImageDisplayRow.Id">
        <RemarksImageDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === ChecklistItemRemarksDisplayRow.Id">
        <ChecklistItemRemarksDisplayCell :data="data" />
      </Box>
      
      <Box v-if="typeId === DefectTypeDisplayRow.Id">
        <DefectTypeDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === StateDisplayRow.Id">
        <StateDisplayCell :data="data" />
      </Box>

      <Box v-if="typeId === BoolDisplayRow.Id">
        <BoolDisplayCell :data="data" />
      </Box>
  
      <Box v-if="typeId === CountryUpdateRow.Id">
        <CountryUpdateCell 
          :showMissingRequirements="showMissingRequirements"
          v-on:click="withButtonPressed"
          v-on:withValue="withValue" 
          :data="data" />
      </Box>

      <Box v-if="typeId === ErrorDisplayRow.Id">
        <ErrorDisplayCell
          v-on:click="withButtonPressed" 
          v-on:withValue="withValue"
          :data="data" />
      </Box>

      <Box v-if="typeId === SpacerDisplayRow.Id">
        <SpacerDisplayCell :data="data" />
      </Box>
      
    </Column>
      
</template>
  
<script>

import { mapGetters } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils  from '@/utils/StringUtils.js';

import MC from "@/domain/session/MC.js";

import Column from '@/portals/shared/library/box/Column.vue';
import Box from '@/portals/shared/library/box/Box.vue';

import BoxDisplayCell from '@/portals/shared/cell/box/BoxDisplayCell.vue';
import BoxDisplayRow from '@/portals/shared/cell/box/BoxDisplayRow.js';

import RowDisplayCell from '@/portals/shared/cell/row/RowDisplayCell.vue';
import RowDisplayRow from '@/portals/shared/cell/row/RowDisplayRow.js';

import TitleDisplayCell from '@/portals/shared/cell/title/TitleDisplayCell.vue';
import TitleDisplayRow from '@/portals/shared/cell/title/TitleDisplayRow.js';

import ButtonDisplayCell from '@/portals/shared/cell/button/ButtonDisplayCell.vue';
import ButtonDisplayRow from '@/portals/shared/cell/button/ButtonDisplayRow.js';

import BoolUpdateCell from '@/portals/shared/cell/bool/BoolUpdateCell.vue';
import BoolUpdateRow from '@/portals/shared/cell/bool/BoolUpdateRow.js';

import BoolDisplayCell from '@/portals/shared/cell/bool/BoolDisplayCell.vue';
import BoolDisplayRow from '@/portals/shared/cell/bool/BoolDisplayRow.js';

import SpacerDisplayCell from '@/portals/shared/cell/spacer/SpacerDisplayCell.vue';
import SpacerDisplayRow from '@/portals/shared/cell/spacer/SpacerDisplayRow.js';

import StringDisplayCell from '@/portals/shared/cell/string/StringDisplayCell.vue';
import StringDisplayRow from '@/portals/shared/cell/string/StringDisplayRow.js';

import LoadingDisplayCell from '@/portals/shared/cell/loading/LoadingDisplayCell.vue';
import LoadingDisplayRow from '@/portals/shared/cell/loading/LoadingDisplayRow.js';

import SavingDisplayCell from '@/portals/shared/cell/saving/SavingDisplayCell.vue';
import SavingDisplayRow from '@/portals/shared/cell/saving/SavingDisplayRow.js';

import CancellingDisplayCell from '@/portals/shared/cell/cancelling/CancellingDisplayCell.vue';
import CancellingDisplayRow from '@/portals/shared/cell/cancelling/CancellingDisplayRow.js';

import LabelValueDisplayCell from '@/portals/shared/cell/label/LabelValueDisplayCell.vue';
import LabelValueDisplayRow from '@/portals/shared/cell/label/LabelValueDisplayRow.js';

import LabelDisplayCell from '@/portals/shared/cell/label/LabelDisplayCell.vue';
import LabelDisplayRow from '@/portals/shared/cell/label/LabelDisplayRow.js';

import VehicleImageDisplayCell from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayCell.vue';
import VehicleImageDisplayRow from '@/portals/shared/cell/vehicle/image/display/VehicleImageDisplayRow.js';

import StatusDisplayCell from '@/portals/shared/cell/status/StatusDisplayCell.vue';
import StatusDisplayRow from '@/portals/shared/cell/status/StatusDisplayRow.js';

import StateDisplayCell from '@/portals/shared/cell/state/StateDisplayCell.vue';
import StateDisplayRow from '@/portals/shared/cell/state/StateDisplayRow.js';

import DefectTypeDisplayCell from '@/portals/shared/cell/defecttype/DefectTypeDisplayCell.vue';
import DefectTypeDisplayRow from '@/portals/shared/cell/defecttype/DefectTypeDisplayRow.js';

import ValueDisplayCell from '@/portals/shared/cell/value/ValueDisplayCell.vue';
import ValueDisplayRow from '@/portals/shared/cell/value/ValueDisplayRow.js';

import ErrorDisplayCell from '@/portals/shared/cell/error/ErrorDisplayCell.vue';
import ErrorDisplayRow from '@/portals/shared/cell/error/ErrorDisplayRow.js';

import CommentImageDisplayCell from '@/portals/shared/cell/comment/CommentImageDisplayCell.vue';
import CommentImageDisplayRow from '@/portals/shared/cell/comment/CommentImageDisplayRow.js';

import CountryUpdateCell from '@/portals/shared/cell/country/CountryUpdateCell.vue';
import CountryUpdateRow from '@/portals/shared/cell/country/CountryUpdateRow.js';


import PictureDisplayCell from '@/portals/shared/cell/picture/PictureDisplayCell.vue';
import PictureDisplayRow from '@/portals/shared/cell/picture/PictureDisplayRow.js';

import PictureUpdateCell from '@/portals/shared/cell/picture/PictureUpdateCell.vue';
import PictureUpdateRow from '@/portals/shared/cell/picture/PictureUpdateRow.js';

import SignatureDisplayCell from '@/portals/shared/cell/signature/SignatureDisplayCell.vue';
import SignatureDisplayRow from '@/portals/shared/cell/signature/SignatureDisplayRow.js';

import StringUpdateCell from '@/portals/shared/cell/string/StringUpdateCell.vue';
import StringUpdateRow from '@/portals/shared/cell/string/StringUpdateRow.js';

import StringListUpdateCell from '@/portals/shared/cell/string/list/StringListUpdateCell.vue';
import StringListUpdateRow from '@/portals/shared/cell/string/list/StringListUpdateRow.js';

import CheckboxUpdateCell from '@/portals/shared/cell/checkbox/CheckboxUpdateCell.vue';
import CheckboxUpdateRow from '@/portals/shared/cell/checkbox/CheckboxUpdateRow.js';

import PhoneNumberUpdateCell from '@/portals/shared/cell/phonenumber/PhoneNumberUpdateCell.vue';
import PhoneNumberUpdateRow from '@/portals/shared/cell/phonenumber/PhoneNumberUpdateRow.js';

import PasswordUpdateCell from '@/portals/shared/cell/password/PasswordUpdateCell.vue';
import PasswordUpdateRow from '@/portals/shared/cell/password/PasswordUpdateRow.js';

import DateUpdateCell from '@/portals/shared/cell/date/DateUpdateCell.vue';
import DateUpdateRow from '@/portals/shared/cell/date/DateUpdateRow.js';

import IntegerUpdateCell from '@/portals/shared/cell/integer/IntegerUpdateCell.vue';
import IntegerUpdateRow from '@/portals/shared/cell/integer/IntegerUpdateRow.js';

import VehicleTypeUpdateCell from '@/portals/shared/cell/vehicle/types/VehicleTypeUpdateCell.vue';
import VehicleTypeUpdateRow from '@/portals/shared/cell/vehicle/types/VehicleTypeUpdateRow.js';

import VehicleScheduleUpdateCell from '@/portals/shared/cell/vehicle/schedules/VehicleScheduleUpdateCell.vue';
import VehicleScheduleUpdateRow from '@/portals/shared/cell/vehicle/schedules/VehicleScheduleUpdateRow.js';

import VehicleBrakeUpdateCell from '@/portals/shared/cell/vehicle/brakes/VehicleBrakeUpdateCell.vue';
import VehicleBrakeUpdateRow from '@/portals/shared/cell/vehicle/brakes/VehicleBrakeUpdateRow.js';

import JurisdictionUpdateCell from '@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateCell.vue';
import JurisdictionUpdateRow from '@/portals/shared/cell/vehicle/jurisdiction/JurisdictionUpdateRow.js';

import VehicleUnitUpdateCell from '@/portals/shared/cell/vehicle/units/VehicleUnitUpdateCell.vue';
import VehicleUnitUpdateRow from '@/portals/shared/cell/vehicle/units/VehicleUnitUpdateRow.js';

import TextAreaUpdateCell from '@/portals/shared/cell/textarea/TextAreaUpdateCell.vue';
import TextAreaUpdateRow from '@/portals/shared/cell/textarea/TextAreaUpdateRow.js';

import EmailUpdateCell from '@/portals/shared/cell/email/EmailUpdateCell.vue';
import EmailUpdateRow from '@/portals/shared/cell/email/EmailUpdateRow.js';

import ScheduleHeaderDisplayRow from "@/portals/shared/cell/schedule/header/ScheduleHeaderDisplayRow.js";
import ScheduleHeaderDisplayCell from "@/portals/shared/cell/schedule/header/ScheduleHeaderDisplayCell.vue";

import ScheduleQuestionDisplayRow from "@/portals/shared/cell/schedule/question/ScheduleQuestionDisplayRow.js";
import ScheduleQuestionDisplayCell from "@/portals/shared/cell/schedule/question/ScheduleQuestionDisplayCell.vue";

import ScheduleQuestionOptionDisplayRow from "@/portals/shared/cell/schedule/question/option/ScheduleQuestionOptionDisplayRow.js";
import ScheduleQuestionOptionDisplayCell from "@/portals/shared/cell/schedule/question/option/ScheduleQuestionOptionDisplayCell.vue";

import ScheduleQuestionRemarksDisplayRow from "@/portals/shared/cell/schedule/question/remark/ScheduleQuestionRemarksDisplayRow.js";
import ScheduleQuestionRemarksDisplayCell from "@/portals/shared/cell/schedule/question/remark/ScheduleQuestionRemarksDisplayCell.vue";

import ChecklistUpdateRow from "@/portals/shared/cell/checklist/update/ChecklistUpdateRow.js";
import ChecklistUpdateCell from "@/portals/shared/cell/checklist/update/ChecklistUpdateCell.vue";

import ChecklistItemUpdateRow from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateRow.js";
import ChecklistItemUpdateCell from "@/portals/shared/cell/checklist/item/ChecklistItemUpdateCell.vue";

import ChecklistHeaderDisplayRow from "@/portals/shared/cell/checklist/header/ChecklistHeaderDisplayRow.js";
import ChecklistHeaderDisplayCell from "@/portals/shared/cell/checklist/header/ChecklistHeaderDisplayCell.vue";

import ChecklistItemDisplayRow from "@/portals/shared/cell/checklist/item/ChecklistItemDisplayRow.js";
import ChecklistItemDisplayCell from "@/portals/shared/cell/checklist/item/ChecklistItemDisplayCell.vue";

import ChecklistItemOptionUpdateRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateRow.js";
import ChecklistItemOptionUpdateCell from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionUpdateCell.vue";

import ChecklistItemOptionDisplayRow from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionDisplayRow.js";
import ChecklistItemOptionDisplayCell from "@/portals/shared/cell/checklist/item/option/ChecklistItemOptionDisplayCell.vue";

import ChecklistItemRemarksDisplayRow from "@/portals/shared/cell/checklist/item/remark/ChecklistItemRemarksDisplayRow.js";
import ChecklistItemRemarksDisplayCell from "@/portals/shared/cell/checklist/item/remark/ChecklistItemRemarksDisplayCell.vue";

import ImageDisplayRow from "@/portals/shared/cell/image/ImageDisplayRow.js";
import ImageDisplayCell from "@/portals/shared/cell/image/ImageDisplayCell.vue";

import EmailImageDisplayRow from "@/portals/shared/cell/image/email/EmailImageDisplayRow.js";
import EmailImageDisplayCell from "@/portals/shared/cell/image/email/EmailImageDisplayCell.vue";

import RemarksImageDisplayRow from "@/portals/shared/cell/image/remarks/RemarksImageDisplayRow.js";
import RemarksImageDisplayCell from "@/portals/shared/cell/image/remarks/RemarksImageDisplayCell.vue";

export default {
  name: "cell-factory",
  components: {
    Box,Column,
    BoxDisplayCell,
    RowDisplayCell,
    TitleDisplayCell, 
    ButtonDisplayCell,
    BoolUpdateCell,
    BoolDisplayCell,
    
    DefectTypeDisplayCell,

    ValueDisplayCell,
    LabelValueDisplayCell,
    LabelDisplayCell,
    ImageDisplayCell,
    EmailImageDisplayCell,
    RemarksImageDisplayCell,
    ErrorDisplayCell,

    LoadingDisplayCell,
    SavingDisplayCell,
    CancellingDisplayCell,
    StatusDisplayCell,
    StateDisplayCell,

    StringDisplayCell,
    StringUpdateCell,
    StringListUpdateCell,
    DateUpdateCell,
    IntegerUpdateCell,
    TextAreaUpdateCell,
    PictureDisplayCell,
    PictureUpdateCell,
    VehicleTypeUpdateCell,
    SignatureDisplayCell,
    VehicleBrakeUpdateCell,
    VehicleUnitUpdateCell,
    JurisdictionUpdateCell,
    VehicleScheduleUpdateCell,
    
    ScheduleHeaderDisplayCell,
    ScheduleQuestionDisplayCell,
    ScheduleQuestionOptionDisplayCell,
    ScheduleQuestionRemarksDisplayCell,
    
    CommentImageDisplayCell,
    CountryUpdateCell,
    
    ChecklistHeaderDisplayCell,
    ChecklistItemUpdateCell,
    ChecklistItemOptionUpdateCell,
    ChecklistItemDisplayCell,
    ChecklistItemOptionDisplayCell,
    ChecklistItemRemarksDisplayCell,
    ChecklistUpdateCell,
    SpacerDisplayCell,
    PasswordUpdateCell,
    CheckboxUpdateCell,
    EmailUpdateCell,
    PhoneNumberUpdateCell,
    VehicleImageDisplayCell,
  },
  
  props: {
    showMissingRequirements: { type: Boolean, default: false },
    showRemove: { type: Boolean, default: true },
    isAdmin: { type: Boolean, default: false },
    showBorder: { type: Boolean, default: false },
    width: { type: Number, default: 15 },
    rowData: null,
  },
  
  data() {
    return {
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      data: {},
      ready: false,
      
      BoxDisplayRow: BoxDisplayRow,
      RowDisplayRow: RowDisplayRow,
      TitleDisplayRow: TitleDisplayRow,
      ButtonDisplayRow: ButtonDisplayRow,
      BoolUpdateRow: BoolUpdateRow,
      BoolDisplayRow: BoolDisplayRow,
      SpacerDisplayRow: SpacerDisplayRow,
      StringUpdateRow: StringUpdateRow,
      StringListUpdateRow: StringListUpdateRow,
      DateUpdateRow: DateUpdateRow,
      StringDisplayRow: StringDisplayRow,
      TextAreaUpdateRow: TextAreaUpdateRow,
      IntegerUpdateRow: IntegerUpdateRow,
      StatusDisplayRow: StatusDisplayRow,
      PictureDisplayRow: PictureDisplayRow,
      PictureUpdateRow: PictureUpdateRow,
      LoadingDisplayRow: LoadingDisplayRow,
      SavingDisplayRow: SavingDisplayRow,
      CancellingDisplayRow: CancellingDisplayRow,
      
      ValueDisplayRow: ValueDisplayRow,
      ErrorDisplayRow: ErrorDisplayRow,
      SignatureDisplayRow: SignatureDisplayRow,
      LabelValueDisplayRow: LabelValueDisplayRow,
      LabelDisplayRow: LabelDisplayRow,
      VehicleImageDisplayRow: VehicleImageDisplayRow,
      CommentImageDisplayRow: CommentImageDisplayRow,
      ImageDisplayRow: ImageDisplayRow,
      RemarksImageDisplayRow: RemarksImageDisplayRow,
      
      EmailImageDisplayRow: EmailImageDisplayRow,
      VehicleTypeUpdateRow: VehicleTypeUpdateRow,
      VehicleBrakeUpdateRow: VehicleBrakeUpdateRow,
      VehicleUnitUpdateRow: VehicleUnitUpdateRow,
      JurisdictionUpdateRow: JurisdictionUpdateRow,
      CountryUpdateRow: CountryUpdateRow,
      VehicleScheduleUpdateRow: VehicleScheduleUpdateRow,
      
      ScheduleHeaderDisplayRow: ScheduleHeaderDisplayRow,
      ScheduleQuestionDisplayRow: ScheduleQuestionDisplayRow,
      ScheduleQuestionOptionDisplayRow: ScheduleQuestionOptionDisplayRow,
      ScheduleQuestionRemarksDisplayRow: ScheduleQuestionRemarksDisplayRow,
      
      ChecklistItemUpdateRow: ChecklistItemUpdateRow,
      ChecklistItemOptionUpdateRow: ChecklistItemOptionUpdateRow,
      ChecklistHeaderDisplayRow: ChecklistHeaderDisplayRow,
      ChecklistItemDisplayRow: ChecklistItemDisplayRow,
      ChecklistItemOptionDisplayRow: ChecklistItemOptionDisplayRow,
      ChecklistItemRemarksDisplayRow: ChecklistItemRemarksDisplayRow,
      ChecklistUpdateRow: ChecklistUpdateRow,

      DefectTypeDisplayRow: DefectTypeDisplayRow,
      StateDisplayRow: StateDisplayRow,
      PasswordUpdateRow: PasswordUpdateRow,
      CheckboxUpdateRow: CheckboxUpdateRow,
      EmailUpdateRow: EmailUpdateRow,
      PhoneNumberUpdateRow: PhoneNumberUpdateRow,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                   ]),
    typeId: function() {
      if (this.data) {
       return this.data.typeId;
      }
      return "";
    }
  },
  watch: {
  },
  
  mounted: function () {
    this.data = this.rowData;
    this.ready = true;
   },
  methods: {
    withValue: function(kvp) {
      this.$emit("withValue", kvp);
    },
    withButtonPressed: function(buttonData) {
      this.$emit("withButtonPressed", buttonData);
    }
  },
}
</script>