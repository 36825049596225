
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class PhoneNumberUpdateRow extends RcTableViewRow {

  static Id = "PhoneNumberUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, PhoneNumberUpdateRow.Id);
  }

}