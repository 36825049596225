
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import ConstUtils from '@/utils/ConstUtils.js';

export default class ScheduleQuestionDisplayRow extends RcTableViewRow {

  static Id = "ScheduleQuestionDisplayRow";
  
  static PART = "part";
  static INITIAL = "initial";
  static ENROUTE = "enroute";
  static REPAIRED = "repaired";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ScheduleQuestionDisplayRow.Id);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  part() {
    return this.getString(ScheduleQuestionDisplayRow.PART);
  }
  
  withPart(part) {
    this.put(ScheduleQuestionDisplayRow.PART, part);
    return this;
  }

  withRepaired(repaired) {
    this.put(ScheduleQuestionDisplayRow.REPAIRED, repaired);
    return this;
  }
  
  repaired() {
    return this.get(ScheduleQuestionDisplayRow.REPAIRED);
  }
  withInitial(option) {
    this.put(ScheduleQuestionDisplayRow.INITIAL, option.id());
    return this;
  }
  
  initialOk() {
    return this.getString(ScheduleQuestionDisplayRow.INITIAL) === ConstUtils.QUESTIONS.VALUES.OK_ID;
  }

  initialNa() {
    return this.getString(ScheduleQuestionDisplayRow.INITIAL) === ConstUtils.QUESTIONS.VALUES.NA_ID;
  }

  enrouteOk() {
    return this.getString(ScheduleQuestionDisplayRow.ENROUTE) === ConstUtils.QUESTIONS.VALUES.OK_ID;
  }

  enrouteNa() {
    return this.getString(ScheduleQuestionDisplayRow.ENROUTE) === ConstUtils.QUESTIONS.VALUES.NA_ID;
  }

  withEnroute(option) {
    this.put(ScheduleQuestionDisplayRow.ENROUTE, option.id());
    return this;
  }
}