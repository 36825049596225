
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class CheckboxUpdateRow extends RcTableViewRow {

  static Id = "CheckboxUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, CheckboxUpdateRow.Id);
  }

}