
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class PasswordUpdateRow extends RcTableViewRow {

  static Id = "PasswordUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, PasswordUpdateRow.Id);
  }

}