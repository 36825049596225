
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class CancellingDisplayRow extends RcTableViewRow {

  static Id = "CancellingDisplayRow";
  
  static Fields = {
      Message: "message",
      Show: "show"
  }
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, CancellingDisplayRow.Id);
  }

  withMessage(message) {
    this.put(CancellingDisplayRow.Fields.Message, message);
    return this;
  }
  
  message() {
    return this.getString(CancellingDisplayRow.Fields.Message);
  }
  
  show() {
    return this.getBoolean(CancellingDisplayRow.Fields.Show);
  }
  
  withShow(value) {
    this.put(CancellingDisplayRow.Fields.Show, value);
    return this;
  }
}