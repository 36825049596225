<template>

  <ValueRow v-if="ready" :align="rowData.align()" :fontSize="rowData.fontSize()">
    <div :class="rowData.style()">
    {{ rowData.value() }}
    </div>
  </ValueRow>
  
</template>

<script>

import { mapGetters } from 'vuex'

import StringDisplayRow from "./StringDisplayRow.js";

import ValueRow from "@/portals/shared/library/value/ValueRow.vue";
export default {
  name: 'string-display-cell',
  components: {
    ValueRow,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new StringDisplayRow(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>