



import RcDomainObject from "../../common/RcDomainObject";

import Employee from "@/domain/model/employee/Employee.js"


export default class ClientException extends RcDomainObject {
  
  static MODEL_NAME = 'ClientException';
  
  static Fields = {
    EMPLOYEE: Employee.MODEL_NAME,
    STACKTRACE: "stacktrace",
    VERSION: "version",
    DEVICE: "deviceId",
    MAKER: "maker",
    CLIENT: "client",
    STATE: "state",
  }
  
  static Values = {
      STATE: {
        SAVED: "saved",
        SENT: "sent",
      },
  }
  
  constructor(domain, data) {
    super(domain, data, ClientException.MODEL_NAME);
  }

  fields() {
    return ClientException.Fields;
  }
  
  find() {
    return this;
  }
  
  withClient() {
    this.put(ClientException.Fields.CLIENT, "client_web");
    return this;
  }
  
  withDevice(device) {
    this.put(ClientException.Fields.DEVICE, device);
    return this;
  }

  withMaker(maker) {
    this.put(ClientException.Fields.MAKER, maker);
    return this;
  }

  withVersion(version) {
    this.put(ClientException.Fields.VERSION, version);
    return this;
  }

  withStateSaved() {
    this.put(ClientException.Fields.STATE, ClientException.Values.STATE.SAVED);
    return this;
  }

  withStateSent() {
    this.put(ClientException.Fields.STATE, ClientException.Values.STATE.SENT);
    return this;
  }

  withStackTrace(stack) {
    this.put(ClientException.Fields.STACKTRACE, stack);
    return this;
  }

  with(obj) {
    super.with(obj);
    return this;
  }
}