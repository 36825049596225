
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";
import ChecklistItemOption from '@/domain/model/checklist/item/option/CheckListItemOption.js';

export default class ChecklistItemOptionUpdateRow extends RcTableViewRow {

  static Id = "ChecklistItemOptionUpdateRow";
  static Option = "option";
  static Index = "index";
  static MaxIndex = "maxIndex";
  static ShowRemove = "showRemove";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistItemOptionUpdateRow.Id);
    this.withObject(ChecklistItemOption.MODEL_NAME);
    this.withRemove(true);
  }

  withOption(option) {
    this.put(ChecklistItemOptionUpdateRow.Option, option);
    return this;
  }
  
  option() {
    return this.get(ChecklistItemOptionUpdateRow.Option);
  }

  index() {
    return this.getInt(ChecklistItemOptionUpdateRow.Index);
  }
  
  withIndex(index) {
    this.put(ChecklistItemOptionUpdateRow.Index, index);
    return this;
  }
  
  withMaxIndex(max) {
    this.put(ChecklistItemOptionUpdateRow.MaxIndex, max);
    return this;
  }
  
  maxIndex() {
    return this.getInt(ChecklistItemOptionUpdateRow.MaxIndex);
  }
  
  withRemove(show) {
    this.put(ChecklistItemOptionUpdateRow.ShowRemove, show);
    return this;
  }
  
  showRemove() {
    return this.getBoolean(ChecklistItemOptionUpdateRow.ShowRemove);
  }
}