
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class BoolUpdateRow extends RcTableViewRow {

  static Id = "BoolUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, BoolUpdateRow.Id);
  }

}