
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class VehicleScheduleUpdateRow extends RcTableViewRow {

  static Id = "VehicleScheduleUpdateRow";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, VehicleScheduleUpdateRow.Id);
  }

  defaultValue() {
    var value = super.defaultValue();
    if (value["@rid"]) {
      value = value["@rid"];
    }
    return value;
  }
}