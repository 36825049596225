<template>

  <LabelValueRow v-if="ready" :class="rowData.even() ? 'rowEven' : 'rowOdd'">
    <template #label>{{ MC.Inspection.Details.SignatureLabel.value() }}</template>
    <template #value>
      <img width="200px" :src="rowData.image()" />
    </template>
  </LabelValueRow>
        
</template>

<script>

import { mapGetters } from 'vuex'

import ContentUtils from '@/utils/ContentUtils.js';
import SignatureDisplayRow from "./SignatureDisplayRow.js";

import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import MC from "@/domain/session/MC.js";

export default {
  name: 'signature-display-cell',
  components: {
    LabelValueRow,
  },
  props: {
    css:    { type: String, default: "" },
    showMissingRequirements: { type: Boolean, default: false },
    data: null,
  },
  data() {
    return {
      ready: false,
      rowData: new SignatureDisplayRow(),
      Contents: ContentUtils,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    row: function() {
      return this.rowData;
    },
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  methods: {
    withValue: function(kvp) {
      if (kvp) {
        kvp['id'] = this.row.id();
        kvp['object'] = this.row.object();
        this.$emit('withValue', kvp);
      }
    }
  },
}
</script>