
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class BoolDisplayRow extends RcTableViewRow {

  static Id = "BoolDisplayRow";
  static Background = "background";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, BoolDisplayRow.Id);
  }

}