
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ErrorDisplayRow extends RcTableViewRow {

  static Id = "ErrorDisplayRow";
  static Error = "Error";

  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ErrorDisplayRow.Id);
  }
  
  error() {
    return this.get(ErrorDisplayRow.Error);
  }

  withError(error) {
    this.put(ErrorDisplayRow.Error, error);
    return this;
  }
  
}