<template>
  <div class="w3-center" :key="redraw">
    <img v-if="isReady"
      :src="displayImage"
      :style="size"
      style="margin-left:0px;margin-top:0px;margin-bottom:0px;" 
      @error="imageError"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConstUtils from '@/utils/ConstUtils.js';

export default {
  name: 'vehicle-image',
  props: {
    // 2. setup external parameters
    defaultValue: Object,
    imageData: {type: String, default: null},
    disableAll: {type: Boolean, default: false},
    mode: {type: String, default: "ro"},
    size: { type: String, default: "width:150px" },
  },
  
  data() {
    // 3. setup page data structures
    return {
      kvps: this.defaultValue,
      images: {},
      isReady: false,
      redraw: 0,
    };
  },
  
  computed: {
    // 4. setup dynamic values
    ...mapGetters([
                   'auth_user', 
                   'auth_client',
                   'auth_connected',
                   'vehicles_found',
                   'companys_found',
                   'types_found',
                   'makers_found',
                   'categorys_found',
                 ]),
    displayImage() {
      var imageUrl = this.defaultValue.image;
      if (this.defaultValue.url) {
        imageUrl = this.defaultValue.url;
      }
      var brokenUrl = this.images[this.defaultValue[ConstUtils.FIELDS.ID]];
      if (brokenUrl) {
        imageUrl = brokenUrl;
      }
      var url = this.auth_client.cdnHost + "/companys/" +  imageUrl;
      if (imageUrl && imageUrl.indexOf("company") != -1) {
        url = this.auth_client.cdnHost + imageUrl;
      }
      if (this.imageData) {
        return this.imageData;
      }
      if (this.defaultValue.imageData) {
        return this.defaultValue.imageData;
      }
      return url;
    }
  },
  
  watch: {
   defaultValue() {
     this.redraw = this.redraw + 1;
   }  
  },
  
  mounted: function() {
    // 5. setup before displayed
    this.isReady = true;
  },
  
  methods: {
    // 6. Methods
    imageError: function() {
      if (this.defaultValue) {
        this.images[this.defaultValue[ConstUtils.FIELDS.ID]] = ConstUtils.VALUES.NO_IMAGE;
      }
      if (event) {
        var imageUrl = this.images[this.defaultValue[ConstUtils.FIELDS.ID]];
        var url = this.auth_client.cdnHost + imageUrl;
        if (!event.target.src.includes(imageUrl)) {
          event.target.src = url;
        }
      }
    },
  }
}

</script>