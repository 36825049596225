
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class DateUpdateRow extends RcTableViewRow {

  static Id = "DateUpdateRow";
      
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, DateUpdateRow.Id);
  }

}