<template>

    <Row v-if="ready" :class="classes()">
      <Column :width="left"></Column>
      <Column :width="middle" style="padding-left: 5px;">
        <slot />
      </Column>
      <Column :width="right"></Column>
    </Row>

</template>

<script>

import { mapGetters } from 'vuex'

import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleDisplayRow from "./TitleDisplayRow.js";

export default {
  name: 'title-display-cell',
  components: {
    Row,
    Column,
  },
  props: {
    align:    { type: String, default: "left" },
    cmpt:     { type: String, default: "title" },
    fontSize: { type: String, default: "medium" },
    subtitle: { type: Boolean, default: false },
    left:     { type: Number, default: 0 },
    right:    { type: Number, default: 0 },
    type:     { type: String, default: "" },
    data:     { type: Object, default: () => {} },
  },
  data() {
    return {
      rowData: new TitleDisplayRow(),
      ready: false,
    }
  },
  computed: {
    ...mapGetters([
                   'domain',
                 ]),
    middle: function() {
      return 15 - this.left - this.right;
    },
    
  },
  mounted: function () {
    this.rowData.data = this.data;
    this.ready = true;
  },
  
  methods: {
    classes: function() {
      var value = "";
      if (this.fontSize) {
        value = "rc-text-" + this.fontSize;
      }

      if (this.subtitle) {
        value = "rc-text-small";
      }
      
      if (this.align === "left") {
        value += " rc-text-left";
      } else if (this.align === "right") {
        value += " rc-text-right";
      } else {
        value += " rc-text-centre";
      }
      return this.background() + " " + value;
    },
    
    background: function() {
      var value = "rc-title-backgroundV2";
      if (this.subtitle) {
        value = "rc-title-background-light";  
      }
      if (this.rowData.savingTitle()) {
        value = "rc-background-good";
        return value;
      }
      if (this.type === "warning") {
        value = " rc-title-warning-left";
      }
      if (this.type === "error") {
        value = " rc-title-error-left";
      }
      return value;
    },
  },
}
</script>