
import RcTableViewRow from "@/portals/shared/cell/common/RcTableViewRow.js";

export default class ChecklistItemDisplayRow extends RcTableViewRow {

  static Id = "ChecklistItemDisplayRow";
  
  static INDEX = "index";
  
  constructor() {
    super();
    this.put(RcTableViewRow.TypeId, ChecklistItemDisplayRow.Id);
  }
  
  
  withLabel(label) {
    this.withName(label).done();
    return this;
  }
  
  label() {
    return this.name();
  }
  
  
  index() {
    return this.getInt(ChecklistItemDisplayRow.INDEX);
  }
  
  withIndex(index) {
    this.put(ChecklistItemDisplayRow.INDEX, index);
    return this;
  }
}